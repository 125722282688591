<template>
  <div class="app-main-content">
    <card title="成员">
      <div class="body-content">
        <div class="header">
          <a-input-search
            v-model="query.keywords"
            @change="getPrjUsers"
            style="width: 200px"
          />
          <div class="right">
            <a-space>
              <a-button
                type="primary"
                @click="$refs.roleMemberModal.open(prjoid, query.role_oid,userlist)"
                v-if="active != -1"
                >添加角色成员</a-button
              >
              <a-button @click="openAddRoleModal">添加角色</a-button>
              <a-button type="primary" @click="$refs.modal.open(prjoid)">添加成员</a-button>
            </a-space>
          </div>
        </div>
        <div class="content">
          <div class="left">
            <div
              class="member-group-item"
              @click="handleTabClick(-1)"
              :class="[active === -1 ? 'active' : '']"
            >
              <div class="text">项目成员</div>
            </div>
            <div
              v-for="(role, index) in allroles"
              class="member-group-item"
              :key="index"
              :class="[active === index ? 'active' : '']"
              @click="handleTabClick(index, role)"
            >
              <div class="text">{{ role.role_name }}</div>
              <a-dropdown>
                <div class="icon">
                  <a-icon type="ellipsis" style="font-size: 25px" />
                </div>
                <a-menu slot="overlay" @click="obj => roleMenuOnClick(obj, role)">
                  <a-menu-item key="delete">
                    移除角色
                  </a-menu-item>
                  <a-menu-item key="permission">
                    设置权限
                  </a-menu-item>
                  <a-menu-item key="roleName">
                    编辑名称
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
          <div class="right">
            <a-table rowKey="oid" :data-source="userlist" :pagination="false">
              <a-table-column title="用户名">
                <template slot-scope="scope, row">
                  <div class="line-center">
                    <a-avatar class="avatar" :size="21" :src="row.pic" />
                    {{ row.username }}
                  </div>
                </template>
              </a-table-column>
              <a-table-column title="姓名" data-index="name"></a-table-column>
              <a-table-column title="岗位" data-index="title"></a-table-column>
              <a-table-column title="邮箱" data-index="email"> </a-table-column>
              <a-table-column title="项目角色" data-index="rolename"></a-table-column>
              <a-table-column
                title="操作"
                key="action"
                width="150px"
                align="center"
              >
                <template slot-scope="scope, row">
                  <a-button type="link" @click="delRoleAccount(row)">
                    删除
                  </a-button>
                </template>
              </a-table-column>
            </a-table>
          </div>
        </div>
      </div>
    </card>
    <add-project-member-modal
      ref="modal"
      @change="getPrjUsers"
    ></add-project-member-modal>
    <add-project-role-member-modal
      ref="roleMemberModal"
      @change="getPrjUsers"
    ></add-project-role-member-modal>
    <a-modal title="编辑角色名称" v-model="roleNameModal.visible" @ok="updateRoleName">
      <a-input v-model="roleNameModal.name"></a-input>
    </a-modal>
    <a-modal
      class="modal"
      title="添加角色"
      v-model="addRoleModal.visible"
      width="680px"
      @ok="addRole"
    >
      <div class="add-role">
        <div class="label">添加角色名称</div>
        <a-input v-model="addRoleModal.form.roleName"></a-input>
      </div>
    </a-modal>
    <!--添加权限-->
    <add-project-role-modal ref="addProRoleModal" :all-funcs="allfuncs" :prj-oid="prjoid"></add-project-role-modal>
<!--    <a-modal-->
<!--      class="modal"-->
<!--      title="添加权限"-->
<!--      v-model="addFunctionModal.visible"-->
<!--      width="680px"-->
<!--      @ok="addFunc"-->
<!--    >-->
<!--      <div class="add-role">-->
<!--        <a-table-->
<!--          rowKey="prjfun_code"-->
<!--          style="width: 630px"-->
<!--          :data-source="allfuncs"-->
<!--          :rowSelection="{-->
<!--            selectedRowKeys,-->
<!--            onChange: changeSelected,-->
<!--          }"-->
<!--          :pagination="false"-->
<!--        >-->
<!--          <a-table-column title="序号" width="100px">-->
<!--            <template #default="_, row, index">-->
<!--              <span>{{ index + 1 }}</span>-->
<!--            </template>-->
<!--          </a-table-column>-->
<!--          <a-table-column-->
<!--            title="权限说明"-->
<!--            data-index="prjfun_name"-->
<!--          ></a-table-column>-->
<!--        </a-table>-->
<!--      </div>-->
<!--    </a-modal>-->
  </div>
</template>

<script>
import { fetch } from "@/utils/request";
import AddProjectMemberModal from "@/components/addProjectMemberModal.vue";
import AddProjectRoleMemberModal from "@/components/addProjectRoleMemberModal.vue";
import addProjectRoleModal from "@/components/addProjectRoleModal.vue";
import appsettings from "@/utils/appsettings";
export default {
  components: { AddProjectRoleMemberModal, AddProjectMemberModal, addProjectRoleModal },
  data() {
    return {
      appsettings: appsettings,
      addRoleModal: {
        visible: false,
        form: {
          roleName: "",
        },
      },
      addFunctionModal: {
        visible: false,
        role_oid:""
      },
      roleNameModal: {
        visible: false,
        name: "",
        oid: "",
        status: ""
      },
      funcChecked: [],
      active: -1,
      prjoid: "",
      allroles: [],
      userlist: [],
      query: {},
      allfuncs: [],
      selectedRowKeys: [],
    };
  },
  created() {
    this.prjoid = this.$route.query.prj_oid;
    this.query.prjoid = this.prjoid;
    this.getRoles();
    this.getPrjUsers();
    this.getFuncs();
  },
  methods: {
    updateRoleName() {
      if (this.roleNameModal.name === "") {
        this.$message.error("请输入角色名")
        return
      }
      fetch("post", "/prj/all/role/update", {
        oid: this.roleNameModal.oid,
        role_name: this.roleNameModal.name,
        prjoid: this.prjoid,
        status: this.roleNameModal.status
      }).then(res => {
        if (res.status === 0) {
          this.$message.success("修改成功")
          this.roleNameModal.visible = false
          this.getRoles()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    roleMenuOnClick({key}, role) {
      switch (key) {
        case "delete":
          this.delRole(role)
          this.getPrjUsers()
          break;
        case "permission":
          this.openAddFuncModal(role)
          break;
        case "roleName":
          this.roleNameModal.name = role.role_name
          this.roleNameModal.oid = role.oid
          this.roleNameModal.visible = true
          this.roleNameModal.status = role.status
          break;
      }
    },
    // 查询项目权限
    getFuncs() {
      fetch("post", "/prj/all/function/retrieve", {
        page_index: 1,
        page_size: 999,
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data.datas
          if (data.length > 0) {
            data.forEach(e => {
              e['value'] = e.prjfun_code
            })
          }
          this.allfuncs = data
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 选择权限
    changeSelected(list) {
      console.log(list);
      this.selectedRowKeys = list;
    },

    // 添加权限弹窗
    openAddFuncModal(row) {
      console.log(".....row", row);
      this.$refs.addProRoleModal.role_oid = row.oid
      this.$refs.addProRoleModal.visible = true
      this.$refs.addProRoleModal.getSelected()
    },

    // 添加角色功能权限
    addFunc() {
      fetch("post", "/prj/role/function/add", {
        prjoid: this.prjoid,
        prjrole_oid:this.addFunctionModal.role_oid,
        prjfun_codes:this.selectedRowKeys
      }).then((res) => {
        if (res.status === 0) {
          this.$message.success("添加成功！");
          this.addFunctionModal.visible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 查询项目角色
    getRoles() {
      fetch("post", "/prj/all/role/retrieve/byprj", {
        prjoid: this.prjoid,
      }).then((res) => {
        if (res.status === 0) {
          this.allroles = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 添加项目角色
    addRole() {
      if (
        this.addRoleModal.form.roleName == null ||
        this.addRoleModal.form.roleName == ""
      ) {
        this.$message.success("请输入角色名称！");
        return;
      }
      fetch("post", "/prj/all/role/create", {
        prjoid: this.prjoid,
        role_name: this.addRoleModal.form.roleName,
      }).then((res) => {
        if (res.status === 0) {
          this.$message.success("添加成功！");
          this.addRoleModal.visible = false;
          this.getRoles();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 删除项目角色
    delRole(item) {
      fetch("post", "/prj/all/role/delete", {
        oid: item.oid,
      }).then((res) => {
        if (res.status === 0) {
          this.$message.success("移除成功！");
          this.getRoles()
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 添加项目角色弹窗
    openAddRoleModal() {
      this.addRoleModal.visible = true;
      this.addRoleModal.form.roleName="";
    },

    // 左侧角色切换
    handleTabClick(index, item) {
      console.log(index);
      this.active = index;
      if (index != -1) this.query.role_oid = item.oid;
      else this.query.role_oid = "";
      this.getPrjUsers();
    },

    // 查询项目成员
    getPrjUsers() {
      fetch("post", "/prj/userlist/list", this.query).then((res) => {
        if (res.status === 0) {
          this.userlist = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 删除项目成员角色
    delRoleAccount(row) {
      console.log(".....row", row);
      if (this.active == -1) {
        fetch("post", "/prj/userlist/delete", {
          prjoid: this.prjoid,
          user_oid: row.user_oid,
        }).then((res) => {
          if (res.status === 0) {
            this.$message.success("删除成功！");
            this.getPrjUsers();
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        let rolenames = row.rolename.split(",");
        let oids = row.rolenameoid.split(",");
        let role_name = this.allroles[this.active].role_name;
        let index = rolenames.indexOf(role_name);
        console.log(".....rolename", rolenames, oids, role_name, index);
        fetch("post", "/prj/userrole/delete", {
          oid: row.userroleoid,
          user_oids: [],
        }).then((res) => {
          if (res.status === 0) {
            this.$message.success("删除成功！");
            this.getPrjUsers();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.app-main-content {
  .body-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    > .header {
      display: flex;
      padding: 0 20px 0 10px;
      height: 55px;
      align-items: center;
      border-bottom: 1px solid #eee;
      flex-shrink: 0;
      .right {
        margin-left: auto;
      }
    }
    > .content {
      flex: 1;
      display: flex;
      > .left {
        height: 100%;
        width: 210px;
        border-right: 1px solid #e4e4e4;
        .member-group-item {
          cursor: pointer;
          display: flex;
          padding: 0 20px;
          align-items: center;
          height: 48px;
          border-bottom: 1px solid #eee;
          border-left: 3px solid rgba(0, 0, 0, 0);
          font-size: 14px;
          &.active {
            background-color: fadeout(@primary-color, 90%);
            color: @primary-color;
            border-left-color: @primary-color;
          }
          .icon {
            margin-left: auto;
          }
        }
      }
      > .right {
        flex: 1;
        height: 100%;
      }
    }
  }
}
/deep/ .ant-modal-title {
  font-weight: 500;
  font-size: 18px;
  color: #303030;
}

.add-role {
  .label {
    color: #303030;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  .ant-input {
    height: 36px;
  }
}
</style>
