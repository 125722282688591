<template>
  <a-modal
    title="添加成员"
    wrapClassName="addProjectMemberModal"
    v-model="visible"
    width="1050px"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="body">
      <div class="left">
        <div class="header">
          <div class="title">人员</div>
        </div>
        <div class="content">
          <a-input
            class="search-input"
            style="margin: 16px; width: 483px"
            v-model="keywords"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
          <edp-tree
            ref="tree"
            style="overflow-y: scroll"
            :data="searchItems"
            @change="clickTree"
            row-key="id"
            open-all
            checkbox
          >
            <template v-slot="{ row }">
              <img style="margin-right: 10px" :src="row.icon" alt="" />
              <a-avatar
                class="avatar"
                v-if="row.pic"
                :size="21"
                :src="row.pic"
              />
              <div class="name">{{ row.title ? row.name + '-' + row.title : row.name }}</div>
              <div v-if="row.email" class="email">({{ row.email }})</div>
            </template>
          </edp-tree>
        </div>
      </div>
      <div class="right">
        <div class="header">
          <div class="title">
            <span class="primary">已选</span>
            <span class="num">(共{{ checked.length }}个成员)</span>
          </div>
          <div class="action">
            <span @click="delAll"
              ><a-icon type="delete" style="margin-right: 5px" />清空</span
            >
          </div>
        </div>
        <div class="content">
          <div class="user-row" v-for="(item, key) in checkedItems" :key="key">
            <a-space>
              <a-avatar class="avatar" :size="21" :src="item.pic" />
              <div class="name">{{ item.title ? item.name + '-' + item.title : item.name }}</div>
              <div class="email" v-if="item.email">({{ item.email }})</div>
            </a-space>
            <div class="remove" @click="removeChecked(item)">
              <a-icon type="close" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import EdpTree from "@/components/edpTree.vue";
import appsettings from "@/utils/appsettings";
import { fetch } from "@/utils/request";

export default {
  name: "addProjectMemberModal",
  components: { EdpTree },
  data() {
    return {
      appsettings: appsettings,
      visible: false,
      checked: [],
      tree: [],
      prjoid: "",
      keywords: "",
      userlist: [],
    };
  },
  computed: {
    checkedItems() {
      let data = [];
      this.tree.forEach((e) => {
        e.children.forEach((c) => {
          this.checked.map((item) => {
            if (item === c.value) {
              data.push(c);
            }
          });
        });
      });
      return data;
    },
    searchItems() {
      return this.tree.filter(i => {
        return i.children.find(f => {
          return f.name.indexOf(this.keywords) !== -1 || f.email.indexOf(this.keywords) !== -1
        })
      }).map(e=>{
        return {
          ...e,
          children: e.children.filter((f)=>{
            return f.name.indexOf(this.keywords) !== -1 || f.email.indexOf(this.keywords) !== -1
          })
        }
      })
    }
  },
  methods: {
    open(prjoid) {
      this.visible = true;
      this.prjoid = prjoid;
      this.checked = [];
      this.getPrjUsers();
    },
    clickTree(row) {
      console.log("......row", row);
      this.checked = row;
    },
    removeChecked(item) {
      console.log(item);
      this.checked = this.checked.filter((i) => i !== item.value);
      this.$refs.tree.checkedKeys = this.checked;
    },
    delAll() {
      this.checked = [];
      this.$refs.tree.checkedKeys = [];
    },
    // 查询人员
    getList() {
      fetch("post", "/cpyaccount/retrieve/bycompany", {
        keywords: this.keywords,
      }).then((res) => {
        if (res.status === 0) {
          let data = res.data.datas;
          let datas = [];
          if (data.length > 0) {
            for (let index = 0; index < data.length; index++) {
              let group = data[index];
              group.children = [];
              for (
                let index2 = 0;
                index2 < data[index].accounts.length;
                index2++
              ) {
                const element = data[index].accounts[index2];
                element["value"] = element.oid;
                element["id"] = element.oid;
                // element["name"] = element.username;
                if (
                  this.userlist.findIndex((i) => i.user_oid == element.oid) < 0
                ) {
                  group.children.push(element);
                }
              }
              if (group.children.length > 0) {
                //group["children"] = data[index].accounts;
                group["name"] = data[index].group_name;
                group["id"] = index;
                group["icon"] = appsettings.imgPrefix + "folder.png";
                datas.push(group);
              }
            }
          }
          this.tree = datas;
          console.log("....tree", this.tree);
        }
      });
    },

    // 查询项目成员
    getPrjUsers() {
      fetch("post", "/prj/userlist/list", { prjoid: this.prjoid }).then(
        (res) => {
          if (res.status === 0) {
            this.userlist = res.data;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    handleOk() {
      let data = {};
      data.user_oids = this.checked;
      data.prjoid = this.prjoid;
      console.log(data);
      fetch("post", "/prj/userlist/add", data).then((res) => {
        if (res.status === 0) {
          this.$message.success("添加成功！");
          this.$emit("change");
          this.visible = false;
        } else {
          this.$message.error(res.message);
          this.visible = false;
        }
      });
    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .addProjectMemberModal {
  .ant-modal-body {
    padding: 0;
  }
}
.body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 450px;
  .avatar {
    width: 21px;
    height: 21px;
    line-height: 21px;
    margin-right: 8px;
  }
  .user-row {
    display: flex;
    align-items: center;
    height: 100%;

    .name {
      font-size: 14px;
      color: #303030;
      margin-right: 8px;
    }
    .email {
      color: #7d7d7d;
      font-size: 12px;
    }
  }
  > .left {
    border-right: 1px solid #e8e8e8;
    display: flex;
    flex-direction: column;
    height: 450px;
    .header {
      flex-shrink: 0;
      height: 49px;
      padding: 0 20px;
      border-bottom: 1px solid #e8e8e8;
      .title {
        display: inline-block;
        height: 100%;
        line-height: 49px;
        color: var(--primary-color);
        border-bottom: 2px solid #08f;
        font-size: 14px;
      }
    }
    .content {
      flex: 1;
      overflow-y: auto;
      padding-top: 5px;
      /deep/ .ant-checkbox-group-item {
        display: flex;
        align-items: center;
        padding: 5px 25px;
        .ant-checkbox {
          top: 0;
        }
      }
      .item {
        padding-left: 6px;
      }
    }
  }
  > .right {
    .header {
      padding: 15px 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        .primary {
          color: #303030;
          font-size: 14px;
          font-weight: 700;
        }
        .num {
          font-size: 12px;
          color: #7d7d7d;
          margin-left: 5px;
        }
      }
    }
    .content {
      padding: 10px 20px;
      .user-row {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
}
</style>
