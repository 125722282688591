<template>
  <a-modal
    title="添加角色成员"
    wrapClassName="addProjectMemberModal"
    v-model="visible"
    width="1050px"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="body">
      <div class="left">
        <div class="header">
          <div class="title">项目人员</div>
        </div>
        <div class="content">
          <a-checkbox-group
            v-model="checked"
            :options="options"
            style="width: 100%"
          >
            <template slot="label" slot-scope="row">
              <div class="user-row">
                <a-space>
                  <a-avatar class="avatar" :size="21" :src="row.pic" />
                  <div class="name">{{ row.title ? row.name + '-' + row.title : row.name }}</div>
                  <div v-if="row.email" class="email">({{ row.email }})</div>
                </a-space>
              </div>
            </template>
          </a-checkbox-group>
        </div>
      </div>
      <div class="right">
        <div class="header">
          <div class="title">
            <span class="primary">已选</span>
            <span class="num">(共{{ checked.length }}个成员)</span>
          </div>
          <div class="action">
            <span @click="checked = []"
              ><a-icon type="delete" style="margin-right: 5px" />清空</span
            >
          </div>
        </div>
        <div class="content">
          <div class="user-row" v-for="(item, key) in checkedItems" :key="key">
            <a-space>
              <a-avatar class="avatar" :size="21" :src="item.pic" />
              <div class="name">{{ item.name }}</div>
              <div v-if="item.email" class="email">({{ item.email }})</div>
            </a-space>
            <div class="remove" @click="removeChecked(item)">
              <a-icon type="close" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { fetch } from "@/utils/request";
import appsettings from "@/utils/appsettings";
export default {
  name: "addProjectRoleMemberModal",
  components: {},
  data() {
    return {
      appsettings: appsettings,
      visible: false,
      checked: [],
      options: [],
      prjoid: "",
      role_oid: "",
      userlist: [],
    };
  },
  computed: {
    checkedItems() {
      return this.checked.map((item) => {
        return this.options.find((i) => item === i.value);
      });
    },
  },
  created() {},
  methods: {
    open(prjoid, role_oid, userlist) {
      console.log("...prjoid", prjoid, role_oid,userlist);
      this.visible = true;
      this.prjoid = prjoid;
      this.role_oid = role_oid;
      this.userlist = userlist;
      this.checked=[];
      this.getPrjUsers();
    },

    removeChecked(item) {
      console.log(item);
      this.checked = this.checked.filter((i) => i !== item.value);
    },

    // 查询项目成员
    getPrjUsers() {
      let query = {
        prjoid: this.prjoid,
      };
      fetch("post", "/prj/userlist/list", query).then((res) => {
        if (res.status === 0) {
          let data = [];
          res.data.forEach((element) => {
            if (this.userlist.findIndex((i)=>i.oid==element.oid)<0) {
              data.push(element);
            }
          });
          if (data.length > 0) {
            data.forEach((e) => {
              e["value"] = e.user_oid;
            });
            this.options = data;
            // console.log(this.options);
          }
          console.log(".....res", this.options);
        }
      });
    },

    handleOk() {
      let data = {};
      data.user_oids = this.checked;
      data.prjoid = this.prjoid;
      data.role_oid = this.role_oid;
      console.log(data);
      fetch("post", "/prj/userrole/add", data).then((res) => {
        if (res.status === 0) {
          this.$message.success("添加成功！");
          this.$emit("change");
          this.visible = false;
        } else {
          this.$message.error(res.message);
          this.visible = false;
        }
      });
    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .addProjectMemberModal {
  .ant-modal-body {
    padding: 0;
  }
}
// e8e8e8
.body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 450px;
  .user-row {
    display: flex;
    justify-content: space-between;
    //height: 100%;
    .avatar {
      width: 21px;
      height: 21px;
      line-height: 21px;
      margin-right: 8px;
    }
    .name {
      font-size: 14px;
      font-weight: 500;
      color: #494949;
      margin-right: 8px;
    }
    .email {
      font-weight: 500;
      color: #7d7d7d;
      font-size: 12px;
      margin-right: 8px;
    }
  }
  > .left {
    border-right: 1px solid #e8e8e8;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .header {
      flex-shrink: 0;
      height: 49px;
      padding: 0 20px;
      border-bottom: 1px solid #e8e8e8;
      .title {
        display: inline-block;
        height: 100%;
        line-height: 49px;
        color: var(--primary-color);
        border-bottom: 2px solid #08f;
        font-size: 14px;
      }
    }
    .content {
      flex: 1;
      overflow-y: auto;
      padding-top: 5px;
      /deep/ .ant-checkbox-group-item {
        display: flex;
        align-items: center;
        padding: 5px 25px;
        .ant-checkbox {
          top: 0;
        }
      }
      .item {
        padding-left: 6px;
      }
    }
  }
  > .right {
    overflow: hidden;
    .header {
      padding: 15px 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        .primary {
          color: #303030;
          font-size: 14px;
          font-weight: 700;
        }
        .num {
          font-size: 12px;
          color: #7d7d7d;
          margin-left: 5px;
        }
      }
    }
    .content {
      padding: 10px 20px;
      overflow-y: auto;
      height: 100%;
      .user-row {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
}
</style>
