<template>
  <a-modal
      class="modal"
      title="添加权限"
      v-model="visible"
      width="1000px"
      @ok="addFunc"
      @cancel="cancel"
  >
    <div class="add-container">
      <div class="add-container-left">
        <div class="left-search">
          <a-input class="search-input" v-model="search" placeholder="请输入权限名称">
            <a-icon slot="prefix" type="search" />
          </a-input>
        </div>
        <div class="left-list">
          <a-checkbox-group v-model="checked" v-if="searchItems.length > 0" :options="searchItems" name="checkboxgroup" @change="onChange">
            <template slot="label" slot-scope="row">
              <span class="name a-style-ellipsis">{{ row.prjfun_name }}</span>
            </template>
          </a-checkbox-group>
          <edp-empty v-else></edp-empty>
        </div>
      </div>
      <div class="add-container-right">
        <div class="right-title">角色权限清单</div>
        <div class="right-list">
          <div class="list-item" v-for="(item, index) in checkedItems" :key="index">
            <div class="name a-style-ellipsis">{{ item.prjfun_name }}</div>
            <div class="remove" @click="removeChecked(item)">
              <a-icon type="close" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {fetch} from "@/utils/request";

export default {
  name: "addProjectRoleModal",
  props: {
    allFuncs: {
      type: Array
    },
    prjOid: {
      type: String
    }
  },
  data() {
    return{
      visible: false,
      role_oid: '',
      search: '',
      checked: [],
      newChecked: []
    }
  },
  watch: {
    role_oid: {
      handler(val) {
        console.log(val);
      }
    }
  },
  computed: {
    searchItems() {
      return this.allFuncs.filter((i) => {
        return i.prjfun_name.indexOf(this.search) !== -1
      })
    },
    checkedItems() {
      return this.newChecked.map(item => {
        return this.allFuncs.find(i => item === i.value)
      })
    }
  },
  methods: {
    addFunc() {
      fetch("post", "/prj/role/function/add", {
        prjoid: this.prjOid,
        prjrole_oid: this.role_oid,
        prjfun_codes: this.newChecked
      }).then((res) => {
        if (res.status === 0) {
          this.$message.success("添加成功！");
          this.visible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getSelected() {
      fetch("post", "/prj/role/function/retrieve", {
        prjrole_oid: this.role_oid
      }).then((res) => {
        if (res.status === 0) {
          let data = [];
          data = res.data.datas.map((item) => {
            return item.prjfun_code;
          });
          this.checked = data
          this.newChecked = data
          console.log(this.checked);
        } else {
          this.$message.error(res.message);
        }
      })
    },
    removeChecked(item) {
      console.log(item)
      this.newChecked = this.newChecked.filter(i => i !== item.value) // 已选中删除
      this.checked = this.checked.filter(i => i !== item.value) // 选中删除
    },
    onChange(checkedList) {
      if (checkedList.length > 0) { // 选取有值时
        checkedList.forEach(e1 => {
          if (!this.newChecked.includes(e1)) { // 总选中数组遍历没有的 push进去
            this.newChecked.push(e1)
          }
        })
      }
      this.searchItems.forEach(e1=>{ // 遍历搜索出来的所有项
        if (!checkedList.includes(e1.value)) { // 当前选中的有没有搜索出来的值
          let index = this.newChecked.indexOf(e1.value)
          if (index !== -1) this.newChecked.splice(index, 1) // 没有就进行删除
        }
      })
      this.checked = this.newChecked
    },
    cancel() {
      this.checked = []
      this.newChecked = []
    }
  }
}
</script>

<style scoped lang="less">
.add-container{
  display: flex;
  height: 600px;

  .add-container-left{
    width: 70%;
    height: 100%;
    overflow: hidden;

    .left-search{
      width: 50%;
      margin-bottom: 15px;
    }
    .left-list{
      height: calc(100% - 47px);
      overflow-y: auto;
     /deep/ .ant-checkbox-group{
        .ant-checkbox-group-item{
          width: 210px;
          margin-bottom: 10px;

          .name{
            display: inline-block;
            vertical-align: top;
            width: 178px;
          }
        }
      }
    }
  }

  .add-container-right{
    width: 30%;
    border-left: 1px solid #e8e8e8;
    padding-left: 15px;
    overflow: hidden;

    .right-title{
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .right-list{
      height: calc(100% - 39px);
      overflow-y: auto;

      .list-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        .name{
          width: 250px;
        }

        .remove{
          cursor: pointer;
        }
      }
    }
  }
}
</style>